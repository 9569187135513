@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.telefone
{
    font-size: 10px;
}

.servicos
{
    font-size: 15pt;
}

.telefone span
{
	font-size: 10pt;
}

.copy
{
    text-align: center;
    color: #666;
    padding-top: 10px;
    margin-top: 0px;
    font-size: 9px;
    font-weight: bold;
}
